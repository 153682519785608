import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Slider from "react-slick";
import { API_BASE_URL } from "../../utils/config";
import { getBannerFeatures } from "../../slices/homeBannerSlice";

const HomeBanner = () => {
  const dispatch = useDispatch();
  const top_banners = useSelector((state) => state.home.dashboard.top_banners);

  useEffect(() => {
    dispatch(getBannerFeatures());
  }, []);

  const bannerFeatures = useSelector((state) => state.homeBanner.features);

  // const homesettings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 300,
  //   slidesToShow: 1,
  //   arrows: true,
  //   // prevArrow: $(".prevbtn"),
  //   // nextArrow: $(".nextbtn"),
  // };

  const homesettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    arrows: true,
    autoplay: true,  // Enables auto sliding
    autoplaySpeed: 15000,  // Time in milliseconds (e.g., 15000ms = 15 seconds) between slides
    // prevArrow: $(".prevbtn"),
    // nextArrow: $(".nextbtn"),
  };
  const sliderRef = useRef(null);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };
  return (
    <div className="sa-home-banner">
      <div className="sasecouter">
        <div className="sa-banner-outer">
          <div className="sa-home-slider">
            <Slider ref={sliderRef} {...homesettings}>
              {top_banners &&
                top_banners.map((item, index) => (
                  <div className="home-item" key={index}>
                    <a href={item.url} target="_blank">
                      <img
                        className="lozad"
                        alt="swop"
                        data-use-lozad="true"
                        data-src={item.image}
                        src={`${API_BASE_URL}${item.image}`}
                        data-loaded="true"
                      />
                    </a>
                  </div>
                ))}
            </Slider>
          </div>
          <div className="slider_arrow">
            <ul>
              <li className="prevarw">
                <a href="javascript:void(0);" className="prevbtn" onClick={handlePrevClick}>
                  <img src="../img/arrow-right.svg" alt="arrow" />
                </a>
              </li>
              <li className="nextarw">
                <a href="javascript:void(0);" className="nextbtn" onClick={handleNextClick}>
                  <img src="../img/arrow-left.svg" alt="arrow" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="cardsection sa-secure-wrap banner-bottom-div">
          <ul className="row">
            {bannerFeatures &&
              bannerFeatures.map((item, index) => (
                <li className="col-sm-3" key={index}>
                  <a href="" className="pr-0">
                    <i className="iconimg">
                      <img
                        className="lozad"
                        alt="swop"
                        data-use-lozad="true"
                        data-src={`${API_BASE_URL}${item.image}`}
                        src={`${API_BASE_URL}${item.image}`}
                        data-loaded="true"
                      />
                    </i>
                    <span className="securecontent">
                      <span className="securehead">{item.title}</span>
                      <span>
                        <span className="secure-inner">{item.description}</span>
                      </span>
                    </span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
