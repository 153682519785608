// * CHANGE FOR PRODUCTION AND TESTING SERVER
  // export const API_URL = "http://local.swop.com/apis/";
  // export const API_BASE_URL = "http://local.swop.com/";

export const API_URL = "https://swopanything.girnarsoft.co.in/apis/";
export const API_BASE_URL = "https://swopanything.girnarsoft.co.in/";

// export const API_URL = "https://m.swopanything.com/apis/";
// export const API_BASE_URL = "https://m.swopanything.com/";

 //export const API_URL = "https://sellanything247.com/apis/";
 //export const API_BASE_URL = "https://sellanything247.com/";
